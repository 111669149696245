(<template>
  <div class="manage-clients">
    <div class="manage-clients__cont">
      <h1 class="manage-clients__title">{{ $gettext('Manage your customers') }}</h1>
      <p class="manage-clients__descr"
         v-html="description"></p>
      <client-item v-for="(client, index) in clients"
                   :key="client.id"
                   :client-index="index"
                   :client="client" />
    </div>
    <div class="manage-clients__btn-cont">
      <button class="sk-btn sk-btn--default manage-clients__add-btn"
              @click="addClient">{{ $gettext('Add new contact') }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';
  import ClientItem from '@/components/manage_clients/ClientItem';

  export default {
    asyncData({store}) {
      let promisesArr = '';
      const uid = store.getters['UserInfoStore/userUid'];
      const canRegisterJobs = store.getters['UserInfoStore/userCanRegisterJobs'];
      if (!store.state.TTManageClientsStore.clients.length && canRegisterJobs) {
        promisesArr = [...promisesArr, store.dispatch('TTManageClientsStore/fetchInterClients', uid)];
      }
      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'client-item': ClientItem
    },
    computed: {
      ...mapState('TTManageClientsStore', {
        clients: (state) => state.clients || []
      }),
      description() {
        const resolvedHref = this.$router.resolve(this.$makeRoute({name: 'ServerPostAssignment'})).href;
        const template = this.$gettext('This page shows all the customers you have worked for outside of the platform. </br></br>You should always keep track of your customers Organisation number, email and phone number, so you can invoice them yourself. If you want the platform to invoice these customers for you, then we will use this information to contact them on your behalf. </br></br>To Register assignments that you have done outside of the platform <a class="sk-link" href="%{ n }">click here</a>.');
        return this.$gettextInterpolate(template, {n: resolvedHref});
      }
    },
    methods: {
      ...mapMutations('TTManageClientsStore', ['resetStore']),
      addClient() {
        this.$store.commit('ModalStore/setModal', {
          component: 'add-client',
          data: {
            title: this.$gettext('Client information'),
            description: this.$gettext('Register your customers information for assignments that you have conducted outside of the platform.'),
            context: this
          }
        });
      }
    },
    beforeRouteLeave(to, from, next) {
      this.resetStore();
      next();
    }
  };
</script>

<style scoped>
  .manage-clients {
    width: 700px;
    margin: 0 auto;
    padding: 20px;
  }

  .manage-clients__cont {
    width: 100%;
    margin: 0 auto 20px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
  }

  .manage-clients__title {
    margin-bottom: 18px;
    padding: 20px 15px 0;
    font-size: 18px;
  }

  .manage-clients__descr {
    padding: 0 15px 20px;
  }

  .manage-clients__btn-cont {
    display: flex;
    justify-content: flex-end;
  }

  .manage-clients__add-btn {
    width: auto;
    padding: 0 20px;
  }

  @media (max-width: 767px) {
    .manage-clients {
      width: 100%;
    }
  }
</style>
